import {
  dataToGeoJSON,
  getImageFiles,
  getKmlDoc,
  isZipped,
  toXML,
  unzip,
} from './kmXLoadUtils';

/**
 * Utils for KMZ/KML files. Reworked version of the original code from https://github.com/Raruto/leaflet-kmz
 */

export function parse(kmzData: ArrayBuffer, props: any) {
  return isZipped(kmzData)
    ? _parseKMZ(kmzData, props)
    : _parseKML(kmzData, props);
}

function _parseKMZ(data: ArrayBuffer, props: { name: string; icons: object }) {
  return unzip(data).then((kmzFiles) => {
    const kmlDoc = getKmlDoc(kmzFiles);
    const images = getImageFiles(Object.keys(kmzFiles));

    const kmlString = kmzFiles[kmlDoc];
    // TODO: use `URL.createObjectURL instead of base64
    // cache all images with their base64 encoding
    props.icons = images.reduce(
      (obj, item) => {
        obj[item] = kmzFiles[item];
        return obj;
      },
      {} as Record<string, string>,
    );

    return _parseKML(kmlString, props);
  });
}

// TODO @aazarov: props
function _parseKML(data: string | ArrayBuffer, props: any) {
  const xml = toXML(data);
  return dataToGeoJSON(xml, props);
}
