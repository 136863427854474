import React, { AnchorHTMLAttributes, LabelHTMLAttributes } from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';

import styles from './Button.module.css';

type ButtonElementProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
type LabelElementProps = DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;
type AnchorElementProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

interface CommonProps {
  component?: React.ElementType;
  children: React.ReactNode;
  handleClick?: () => void;
  appearance: 'primary' | 'secondary' | 'auth';
  isFillLayout?: boolean;
  size?: 'medium' | 'large';
  className?: string;
}

type ButtonProps = CommonProps &
  (ButtonElementProps | LabelElementProps | AnchorElementProps);

const Button: React.FC<ButtonProps> = ({
  component = 'button',
  children,
  handleClick,
  appearance = 'primary',
  isFillLayout = false,
  size = 'medium',
  className,
  ...props
}) => {
  const ElementType = component;
  return (
    <ElementType
      className={cn(
        styles.Button,
        styles[appearance],
        styles[size],
        className,
        {
          [styles.fill]: isFillLayout,
        },
      )}
      onClick={handleClick}
      {...props}
    >
      {children}
    </ElementType>
  );
};

export default Button;
