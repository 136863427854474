import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Player.css';
import Input from '../Input/Input';
import { useRecoilState } from 'recoil';
import { videoInfoState, videoListState } from '../../atoms/video';
import Button from '../Button/Button';
import { convertToCSV, parseCSV } from '../../utils/csvUtils';
import { hmsToSeconds, secondsToHms } from '../../utils/timeUtils';
import { notification } from 'antd';

interface LatLng {
  lat: number;
  lng: number;
}

interface PlayerProps {
  segmentStartTime?: string;
  segmentTitle?: string;
}

const Player: React.FC<PlayerProps> = ({ segmentStartTime, segmentTitle }) => {
  const [notify] = notification.useNotification();
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const [currentTime, setCurrentTime] = useState(0);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [title, setTitle] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const [videoInfo, setVideoInfo] = useRecoilState(videoInfoState);
  const [videoList, setVideoList] = useRecoilState(videoListState);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setVideoSrc(fileUrl);
      setVideoInfo((prev) => ({
        ...prev,
        fileName: file.name,
      }));
      openVideoNotification([file]);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedMetadata = () => {
        videoElement.currentTime = 0;
      };

      const handleTimeUpdate = () => {
        setCurrentTime(Math.floor(videoElement.currentTime)); // Ensure current time is rounded down to the nearest integer
      };

      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
        );
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [videoSrc]);

  const handleAddTimeCode = () => {
    setStartTime(secondsToHms(currentTime));
  };

  const handleStopTimeCode = () => {
    if (startTime !== null) {
      const endHms = secondsToHms(currentTime);
      setEndTime(endHms);
      setVideoInfo((prev) => ({
        ...prev,
        startTime: startTime,
        endTime: endHms,
        title: title,
      }));
      console.log('videoInfo', videoInfo);
      setVideoList((prev) => [
        ...prev,
        {
          fileName: videoInfo.fileName,
          startTime: startTime,
          endTime: endHms,
          title: title,
          location: videoInfo.location,
          key: uuidv4(),
        },
      ]);

      setStartTime(null);
      setEndTime(null);
    }
  };

  const handleExportCSV = () => {
    const csvData = convertToCSV(videoList);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${videoInfo.fileName.split('.')[0]}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target?.result as string;
        const importedVideoList = parseCSV(csvContent);
        setVideoList(importedVideoList);
      };
      reader.readAsText(file);
      openCsvNotification([file]);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && segmentStartTime) {
      const segmentStartSeconds = hmsToSeconds(segmentStartTime);
      console.log('Setting currentTime to:', segmentStartSeconds); // Debugging log
      if (!isNaN(segmentStartSeconds)) {
        videoElement.currentTime = segmentStartSeconds;
        videoElement.play().catch((error) => {
          console.error('Error playing video:', error);
        });
      }
    }
  }, [segmentStartTime]);

  const openVideoNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Відео додано',
        description: `Відео ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  const openCsvNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Мета данні додано',
        description: `Мета данні ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  return (
    <div className="Player_Container">
      <div>
        <h3>
          Video Player <span className="Segment_Title">{segmentTitle}</span>
        </h3>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => fileInputRef.current?.click()}
        >
          Choose Video
        </Button>
        {videoSrc && (
          <div>
            <Input
              title="Title"
              value={title}
              onChange={(value) => {
                setTitle(value);
              }}
            />
            <video key={videoSrc} ref={videoRef} width="95%" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
      <div className="Buttons_Container">
        {startTime === null && (
          <Button appearance="primary" handleClick={handleAddTimeCode}>
            Add time code
          </Button>
        )}
        {startTime !== null && (
          <Button appearance="primary" handleClick={handleStopTimeCode}>
            Stop time code
          </Button>
        )}
        <Button appearance="primary" handleClick={handleExportCSV}>
          Export time code
        </Button>
        <input
          ref={csvInputRef}
          type="file"
          accept=".csv"
          onChange={handleImportCSV}
          style={{ display: 'none' }}
        />
        <Button
          appearance="primary"
          handleClick={() => csvInputRef.current?.click()}
        >
          Import time code
        </Button>
      </div>
    </div>
  );
};

export default Player;
