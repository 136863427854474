import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Player from '../components/Player/Player';
import GMap from '../components/GMap/GMap';
import Table from '../components/Table/Table';
import useAuth from '../hooks/useAuth';
import { VideoInfo } from '../atoms/video';
import { hmsToSeconds } from '../utils/timeUtils';
import ContentLayout from '../layout/ContentLayout/ContentLayout';

const Editor: React.FC = () => {
  const { user, role, loading } = useAuth();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentSegment, setCurrentSegment] = useState<VideoInfo | null>(null);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/auth');
    }

    if (role && role !== 'editor') {
      navigate('/viewer');
    }
  }, [loading, user, navigate, role]);

  const handleMarkerClick = (segment: VideoInfo) => {
    if (videoRef.current) {
      videoRef.current.currentTime = hmsToSeconds(segment.startTime);
      videoRef.current.play();
    }
    setCurrentSegment(segment);
  };

  const handleRowClick = (segment: VideoInfo) => {
    handleMarkerClick(segment);
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading message or spinner while checking auth state
  }

  if (!user) {
    return null; // Or a loading spinner
  }

  return (
    <ContentLayout>
      <div className="Container">
        <Player
          segmentStartTime={currentSegment?.startTime}
          segmentTitle={currentSegment?.title}
        />
        <GMap
          activeSegment={currentSegment}
          handleMarkerClick={handleMarkerClick}
        />
      </div>
      <Table activeSegment={currentSegment} onRowClick={handleRowClick} />
    </ContentLayout>
  );
};

export default Editor;
