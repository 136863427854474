import React, { useState, useEffect, useRef, useMemo } from 'react';
import './Player.css';
import { useRecoilState } from 'recoil';
import { videoListState } from '../../atoms/video';
import Button from '../Button/Button';
import { convertToCSV, parseCSV } from '../../utils/csvUtils';
import { hmsToSeconds } from '../../utils/timeUtils';
import { notification, Skeleton } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';

interface PlayerProps {
  selectedVideo: string;
  segmentStartTime: string;
  segmentEndTime: string; // Added this prop
  segmentTitle?: string;
  segmentFileName?: string;
  onSegmentEnd?: (nextSegmentIndex: number) => void;
}

const Player: React.FC<PlayerProps> = ({
  selectedVideo,
  segmentStartTime,
  segmentEndTime,
  segmentTitle,
  onSegmentEnd,
  segmentFileName,
}) => {
  const [notify] = notification.useNotification();
  const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({});
  const [videoSrcs, setVideoSrcs] = useState<{ [fileName: string]: string }>(
    {},
  );
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const [playOnlyTimecode, setPlayOnlyTimecode] = useState<boolean>(true);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newVideoSrcs: { [fileName: string]: string } = {};
      Array.from(files).forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        newVideoSrcs[file.name] = fileUrl;
      });
      setVideoSrcs(newVideoSrcs);
      openVideoNotification(Array.from(files));
    }
  };

  const handlePlayOnlySegments = (event: CheckboxChangeEvent) => {
    setPlayOnlyTimecode(event.target.checked);
  };

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target?.result as string;
          const importedVideoList = parseCSV(csvContent);
          setVideoList((prevList) => [...prevList, ...importedVideoList]);
          openCsvNotification(Array.from(files));
        };
        reader.readAsText(file);
      });
    }
  };

  useEffect(() => {
    const videoElement = videoRefs.current[selectedVideo];
    if (videoElement && segmentStartTime && segmentEndTime) {
      const segmentStartSeconds = hmsToSeconds(segmentStartTime);
      const segmentEndSeconds = hmsToSeconds(segmentEndTime);

      if (!isNaN(segmentStartSeconds) && !isNaN(segmentEndSeconds)) {
        videoElement.currentTime = segmentStartSeconds;
        videoElement.play().catch((error) => {
          console.error('Error playing video:', error);
        });

        // Add timeupdate event listener to check current play time
        const handleTimeUpdate = () => {
          if (
            videoElement.currentTime >= segmentEndSeconds &&
            playOnlyTimecode
          ) {
            console.log(
              'Reached segment end time, triggering onSegmentEnd.',
              playOnlyTimecode,
            ); // Debugging log
            videoElement.pause(); // Optionally pause the video
            if (onSegmentEnd) {
              const currentIndex = videoList.findIndex((segment) => {
                return (
                  segment.fileName === selectedVideo &&
                  hmsToSeconds(segment.startTime) === segmentStartSeconds &&
                  hmsToSeconds(segment.endTime) === segmentEndSeconds
                );
              });
              onSegmentEnd(currentIndex + 1);
            }
          }
        };

        videoElement.addEventListener('timeupdate', handleTimeUpdate);

        // Cleanup the event listener when the component unmounts or the video changes
        return () => {
          videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
      }
    }
  }, [
    selectedVideo,
    segmentStartTime,
    segmentEndTime,
    onSegmentEnd,
    videoList,
  ]);

  const openVideoNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Відео додано',
        description: `Відео ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  const openCsvNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Мета данні додано',
        description: `Мета данні ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  // TODO clarify the flow for the multiple videos
  const visibleVideoFile = useMemo(() => {
    if (Object.keys(videoSrcs).length === 1 || !selectedVideo) {
      return Object.keys(videoSrcs)[0] ?? '';
    }

    return selectedVideo;
  }, [videoSrcs, selectedVideo]);

  return (
    <div className="PlayerMapper_Container">
      <div>
        <h3>
          Video Player:{segmentFileName}
          <span className="Segment_Title">{segmentTitle}</span>
        </h3>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => fileInputRef.current?.click()}
        >
          Choose Videos
        </Button>
        <input
          type="file"
          accept=".csv"
          multiple
          onChange={handleImportCSV}
          style={{ display: 'none' }}
          ref={csvInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => csvInputRef.current?.click()}
        >
          Import CSVs
        </Button>
        <Checkbox onChange={handlePlayOnlySegments} defaultChecked={true}>
          Play only timecodes
        </Checkbox>
        <div>
          {!Object.keys(videoSrcs).length && (
            <Skeleton paragraph={{ rows: 4 }} />
          )}
          {Object.keys(videoSrcs).map((fileName) => (
            <video
              key={fileName}
              ref={(el) => (videoRefs.current[fileName] = el)}
              width="95%"
              controls
              style={{
                display: visibleVideoFile === fileName ? 'block' : 'none',
              }}
            >
              <source src={videoSrcs[fileName]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Player;
