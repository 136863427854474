import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import './GMap.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  filteredVideoListState,
  VideoInfo,
  videoInfoState,
  videoListState,
} from '../../atoms/video';

const containerStyle = {
  width: '50%',
  height: '90vh',
  flexBasis: '50%',
};

const center = {
  lat: 49.024502,
  lng: 31.419201,
};

interface LatLng {
  lat: number;
  lng: number;
}

interface GMapProps {
  handleMarkerClick?: (segment: VideoInfo) => void;
  activeSegment?: VideoInfo | null;
}

const GMap: React.FC<GMapProps> = ({ handleMarkerClick, activeSegment }) => {
  const [markerPosition, setMarkerPosition] = useState<LatLng | undefined>();
  const [mapRef, setMapRefRef] = React.useState<google.maps.Map>();
  const [videoInfo, setVideoInfo] = useRecoilState(videoInfoState);
  const [, setVideoList] = useRecoilState(videoListState);
  const videoList = useRecoilValue(filteredVideoListState);
  const [selectedSegment, setSelectedSegment] = useState<VideoInfo | null>(
    null,
  );
  const [activeMarker, setActiveMarker] = useState<google.maps.Marker | null>(
    null,
  );
  const [routePath, setRoutePath] = useState<LatLng[] | undefined>(undefined);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const currentLocation = {
      lat: event!.latLng!.lat(),
      lng: event!.latLng!.lng(),
    };
    setMarkerPosition(currentLocation);
    setVideoInfo((videoInfo) => ({ ...videoInfo, location: currentLocation }));
    event.stop();
  };

  const handleMarkerClickInternal = (segment: VideoInfo) => {
    setSelectedSegment(segment);
    setMarkerPosition(segment.location);
    if (handleMarkerClick) {
      handleMarkerClick(segment);
    }
  };

  const handlerMarkerDrag = (
    event: google.maps.MapMouseEvent,
    segment: VideoInfo,
  ) => {
    const updatedVideoList = videoList.map((seg) => {
      if (seg.key === segment.key) {
        return {
          ...seg,
          location: { lat: event!.latLng!.lat(), lng: event!.latLng!.lng() },
        };
      }
      return seg;
    });
    setVideoList(updatedVideoList);
  };

  useEffect(() => {
    setRoutePath(videoList.map((segment) => segment.location as LatLng));
  }, [videoList]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
    >
      <GoogleMap
        onLoad={(map) => {
          setMapRefRef(map);
          map.addListener('click', handleMapClick);
          map.setCenter(
            activeSegment ? activeSegment.location : markerPosition || center,
          );
        }}
        mapContainerStyle={containerStyle}
        options={{
          gestureHandling: 'greedy',
          mapTypeId: 'hybrid',
          zoom: mapRef?.getZoom() || 6,
        }}
      >
        {markerPosition && (
          <Marker
            onLoad={(marker) => setActiveMarker(marker)}
            position={markerPosition}
            draggable={true}
            onRightClick={(e) => setMarkerPosition(undefined)}
          />
        )}
        {videoList.map((segment, index) => (
          <Polyline
            key={index}
            path={routePath}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 1.0,
              strokeWeight: 2,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: '100%',
                  repeat: '30%',
                },
              ],
            }}
          />
        ))}
        {videoList.map(
          (segment, index) =>
            segment.location && (
              <Marker
                key={index}
                position={segment.location}
                onDrag={(event) => handlerMarkerDrag(event, segment)}
                onClick={() => handleMarkerClickInternal(segment)}
                draggable={true}
                icon={{
                  url:
                    activeSegment && activeSegment.fileName === segment.fileName
                      ? activeSegment === segment
                        ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                        : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                      : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                }}
              />
            ),
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GMap;
