import JSZip from 'jszip';
import * as toGeoJSON from '@tmcw/togeojson';
import { FeatureCollection, Geometry } from 'geojson';

/**
 * Utils for KMZ/KML files. Reworked version of the original code from https://github.com/Raruto/leaflet-kmz
 */

export function loadFiles(kmzFiles: File[]): Promise<[string, ArrayBuffer][]> {
  return Promise.all(
    kmzFiles.map(async (file) => {
      const fileData = await loadFile(file);

      return [file.name, fileData];
    }),
  );
}

export function loadFile(kmzFile: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result as ArrayBuffer);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsArrayBuffer(kmzFile);
  });
}

export function getKmlDoc(files: Record<string, string>) {
  return getKmlFiles(Object.keys(files))[0];
}

export function getKmlFiles(files: string[]) {
  return files.filter((file) => isKmlFile(file));
}

export function getImageFiles(files: string[]) {
  return files.filter((file) => isImageFile(file));
}

export function getFileExt(filename: string): string {
  return filename.split('.').pop()?.toLowerCase().replace('jpg', 'jpeg') || '';
}

export function getMimeType(filename: string, ext: string) {
  let mime = 'text/plain';
  if (/\.(jpe?g|png|gif|bmp)$/i.test(filename)) {
    mime = 'image/' + ext;
  } else if (/\.kml$/i.test(filename)) {
    mime = 'text/plain';
  }
  return mime;
}

export function isImageFile(filename: string) {
  return /\.(jpe?g|png|gif|bmp)$/i.test(filename);
}

export function isKmlFile(filename: string) {
  return /.*\.kml/.test(filename);
}

/**
 * It checks if a given file begins with PK, if so it's zipped
 *
 * @link https://en.wikipedia.org/wiki/List_of_file_signatures
 */
export function isZipped(file: ArrayBuffer) {
  return (
    'PK' === String.fromCharCode(...Array.from(new Uint8Array(file, 0, 2)))
  );
}

export function dataToGeoJSON(
  data: XMLDocument | string,
  props: any,
): FeatureCollection<Geometry | null> {
  const xml = data instanceof XMLDocument ? data : toXML(data);
  const json = toGeoJSON.kml(xml);

  // TODO @aazarov: no clue why it was done so, fix implementation
  (json as any).properties = {
    ...(json as any).properties,
    ...props,
  };
  return json;
}

export function toXML(data: ArrayBuffer | string): XMLDocument {
  let text: string;
  if (data instanceof ArrayBuffer) {
    text = new Uint8Array(data).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, '');
    const encoding = text
      .substring(0, text.indexOf('?>'))
      .match(/encoding\s*=\s*["'](.*)["']/i);
    if (encoding) {
      text = new TextDecoder(encoding[1]).decode(data);
    }
  } else {
    text = data;
  }
  return text
    ? new DOMParser().parseFromString(text, 'text/xml')
    : document.implementation.createDocument(null, 'kml');
}

export function unzip(folder: ArrayBuffer): Promise<Record<string, string>> {
  return new Promise((resolve) => {
    JSZip.loadAsync(folder).then((zip) => {
      // Parse KMZ files.
      const files = Object.keys(zip.files).map((name) => {
        const entry = zip.files[name];
        // TODO use URL.createObjectURL instead of base64 too
        if (isImageFile(name)) {
          const ext = getFileExt(name);
          const mime = getMimeType(name, ext);
          return entry
            .async('base64')
            .then((value) => [name, 'data:' + mime + ';base64,' + value]);
        }
        return entry.async('text').then((value) => [name, value]); // [ fileName, stringValue ]
      });

      // Return KMZ files.
      Promise.all(files).then((list) =>
        resolve(
          list.reduce(
            (obj, item) => {
              obj[item[0]] = item[1]; // { fileName: stringValue }
              return obj;
            },
            {} as Record<string, string>,
          ),
        ),
      );
    });
  });
}
