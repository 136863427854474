import { atom, selector } from 'recoil';

interface Location {
  lat: number;
  lng: number;
}

export interface VideoInfo {
  fileName: string;
  startTime: string;
  endTime: string;
  title: string;
  location: Location;
  key: string;
}

export const videoInfoState = atom<VideoInfo>({
  key: 'videoInfoState',
  default: {
    fileName: '',
    startTime: '00:00:00',
    endTime: '00:00:00',
    title: '',
    location: { lat: 0, lng: 0 },
    key: 'uuid',
  },
});

export const videoListState = atom<VideoInfo[]>({
  key: 'videoListState',
  default: [],
});

export const videoListFilterState = atom({
  key: 'videoListFilter',
  default: {
    title: '',
  },
});

export const filteredVideoListState = selector({
  key: 'filteredVideoListState',
  get: ({ get }) => {
    const filter = get(videoListFilterState);
    const list = get(videoListState);

    if (!filter.title) return list;

    return list.filter(({ title }) =>
      title?.toLowerCase().includes(filter.title.toLowerCase()),
    );
  },
});
