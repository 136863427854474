// src/components/Auth/AuthForm.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebaseConfig';
import { signInWithEmailAndPassword, User } from 'firebase/auth';
import { Form, Input, Typography, Alert } from 'antd';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Button from '../Button/Button';

const { Title } = Typography;

const AuthForm: React.FC<{ onLogin: (user: any) => void }> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  async function createUserDocument(user: User) {
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return;
    }
    await setDoc(userDocRef, {
      email: user.email,
      role: 'viewer',
    });
  }

  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      onLogin(userCredential.user);
      await createUserDocument(userCredential.user);
      setError(null);
      navigate('/viewer'); // Redirect to the home page
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Invalid email or password.');
    }
  };

  return (
    <Form
      name="login"
      initialValues={{ remember: true }}
      onFinish={handleSignIn}
      layout="vertical"
    >
      {error && <Alert message={error} type="error" showIcon />}
      <Form.Item
        layout="vertical"
        label="Електронна пошта"
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input
          size="large"
          type="email"
          placeholder="example@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Пароль"
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input.Password
          size="large"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button appearance="primary" size="large" isFillLayout>
          Увійти
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AuthForm;
