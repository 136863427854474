import { NavLink } from 'react-router-dom';
import './MenuLink.css';

interface MenuLinkProps {
  href: string;
  title: string;
}

const MenuLink = ({ title, href }: MenuLinkProps) => {
  return (
    <NavLink
      to={href}
      className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
    >
      {title}
    </NavLink>
  );
};

export default MenuLink;
