import { useState, useEffect } from 'react';
import { firestore } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';

type RoleType = 'admin' | 'editor' | 'viewer' | null;

const useUserRole = (user: User | null) => {
  const [role, setRole] = useState<RoleType>(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role as RoleType);
        }
      }
    };

    fetchUserRole();
  }, [user]);

  return role;
};

export default useUserRole;
