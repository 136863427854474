// src/hooks/useAuth.js
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useUserRole from './useUserRole';

/**
 * @typedef {import('firebase/auth').User} UserType
 */
const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const auth = getAuth();
  const role = useUserRole(user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false once the auth state is resolved
    });
    return () => unsubscribe();
  }, [auth]);

  return { user, role, loading };
};

export default useAuth;
