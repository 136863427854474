import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import Header from './Header/Header';

import styles from './Layout.module.css';

interface LayoutProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default Layout;
