import { v4 as uuidv4 } from 'uuid';

export const convertToCSV = (array: any[]) => {
  const header =
    Object.keys(array[0])
      .filter((key) => key !== 'key')
      .map((key) => {
        if (typeof array[0][key] === 'object' && array[0][key] !== null) {
          return Object.keys(array[0][key])
            .map((subKey) => `${key}.${subKey}`)
            .join(',');
        }
        return key;
      })
      .join(',') + '\n';

  const rows = array
    .map((obj) => {
      return Object.keys(obj)
        .filter((key) => key !== 'key')
        .map((key) => {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            return Object.values(obj[key])
              .map((val) => `"${val}"`)
              .join(',');
          }
          return `"${obj[key]}"`;
        })
        .join(',');
    })
    .join('\n');

  return header + rows;
};

export const parseCSV = (csv: string) => {
  const [header, ...rows] = csv.trim().split('\n');
  const keys = header.split(',');

  return rows.map((row) => {
    const values = row.split('","').map((val) => val.replace('"', '')); // dirty hack, but works
    const obj: any = { key: uuidv4() };
    keys.forEach((key, index) => {
      const [mainKey, subKey] = key.split('.');
      if (subKey) {
        if (!obj[mainKey]) {
          obj[mainKey] = {};
        }
        obj[mainKey][subKey] = parseFloat(values[index]) || values[index];
      } else {
        obj[key] = values[index];
      }
    });
    return obj;
  });
};
