import React from 'react';

import { User } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import Button from '../Button/Button';

import './Welcome.css';

interface WelcomeProps {
  user: User | null;
  onLogout?: () => void;
}

const Welcome = ({ user, onLogout }: WelcomeProps) => {
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      onLogout && onLogout();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="welcome-container">
      {user && (
        <>
          <span>Welcome, {user.email}</span>
          <Button appearance="auth" onClick={handleSignOut}>
            Sign Out
          </Button>
        </>
      )}
    </div>
  );
};

export default Welcome;
