import React, { useState } from 'react';
import { parse } from 'date-fns';
import { isMatch } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import type { TableProps as TablePropsAntd } from 'antd';
import { Form, Table as TableAnt, Input } from 'antd';

import {
  VideoInfo,
  videoListState,
  filteredVideoListState,
  videoListFilterState,
} from '../../atoms/video';
import Button from '../Button/Button';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import DragHandle from './DragHandleButton';

import './Table.css';

interface TableProps {
  activeSegment?: VideoInfo | null;
  onRowClick?: (segment: VideoInfo) => void;
  isEditable?: boolean;
}

interface VideoInfoWithStringLocation extends Omit<VideoInfo, 'location'> {
  location: string;
}

const Table: React.FC<TableProps> = ({
  activeSegment,
  onRowClick,
  isEditable = false,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: VideoInfo) => record.key === editingKey;

  const [, setVideoList] = useRecoilState(videoListState);
  const [filter, setFilter] = useRecoilState(videoListFilterState);
  const videoList = useRecoilValue(filteredVideoListState);

  const handleDelete = (data: VideoInfo) => {
    setVideoList((prev) => prev.filter((video) => !isMatch(video, data)));
  };

  const handleEdit = (updatedRow: VideoInfoWithStringLocation) => {
    form.setFieldsValue({ ...updatedRow });
    setEditingKey(updatedRow.key);
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const handleSave = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as VideoInfo;
      const { location } = row;
      const [lat, lng] = (location as unknown as string).split(',').map(Number);
      row.location = { lat, lng };

      setVideoList((prevVideoList) => {
        return prevVideoList.map((item) =>
          item.key === key ? { ...item, ...row } : item,
        );
      });

      setEditingKey('');
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
    }
  };

  const renderActionButtons = (data: VideoInfo) => {
    const editable = isEditing(data);
    const { lat, lng } = data.location;
    const updatedLocation = `${lat}, ${lng}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { location, ...restData } = data;
    const editableData = { location: updatedLocation, ...restData };

    return !isEditable ? (
      <div className="container-button">
        {editable ? (
          <div className="container-button-editable">
            <Button appearance="secondary" onClick={() => handleSave(data.key)}>
              Save
            </Button>
            <Button appearance="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            appearance="secondary"
            disabled={editingKey !== ''}
            onClick={() => handleEdit(editableData)}
          >
            Edit
          </Button>
        )}
        <Button appearance="primary" handleClick={() => handleDelete(data)}>
          Delete
        </Button>
      </div>
    ) : null;
  };

  const sorter = (a: VideoInfo, b: VideoInfo) => {
    const timeFormat = 'HH:mm:ss';
    const dateA = parse(a.startTime, timeFormat, new Date(0));
    const dateB = parse(b.startTime, timeFormat, new Date(0));
    return dateA.getTime() - dateB.getTime();
  };

  const defaultColumns = [
    {
      key: 'sort',
      render: () => <DragHandle />,
      editable: false,
    },
    {
      title: 'File name',
      dataIndex: 'fileName',
    },
    {
      title: 'Start time',
      dataIndex: 'startTime',
      editable: !isEditable,
      sorter: sorter,
    },
    {
      title: 'End time',
      dataIndex: 'endTime',
      editable: !isEditable,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      editable: !isEditable,
      filtered: !!filter.title,
      filterDropdown: (
        <div className="name-filter-dropdown">
          <Input
            placeholder="Enter file name"
            onChange={(e) => setFilter({ title: e.target.value })}
          />
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      render: (location: VideoInfo['location']) => {
        return `${location.lat}, ${location.lng}`;
      },
      editable: !isEditable,
    },
    {
      title: 'Action',
      render: (_: unknown, data: VideoInfo) => renderActionButtons(data),
    },
  ];

  const columns: TablePropsAntd['columns'] = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: VideoInfo) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setVideoList((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id,
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const onChange: TablePropsAntd<VideoInfo>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    if (extra.action === 'sort') {
      setVideoList(extra.currentDataSource);
    }
  };

  return (
    <div className="Table_Container">
      <Form form={form} component={false}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={videoList.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <TableAnt
              dataSource={videoList}
              components={{
                body: {
                  cell: EditableCell,
                  row: EditableRow,
                },
              }}
              columns={columns}
              rowClassName={(record) =>
                record === activeSegment ? 'active-row' : ''
              }
              onChange={onChange}
              onRow={(record) => ({
                onClick: () => onRowClick && onRowClick(record),
              })}
            />
          </SortableContext>
        </DndContext>
      </Form>
    </div>
  );
};

export default Table;
