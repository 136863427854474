import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';

import LogoImage from './logo.png';
import styles from './Logo.module.css';

interface LogoProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLogin?: boolean;
}

export const Logo = ({ isLogin = false }: LogoProps) => {
  return (
    <div
      className={cn(styles.containerLogo, {
        [styles.loginContainer]: isLogin,
      })}
    >
      <img
        src={LogoImage}
        alt="Logo"
        className={cn(styles.logo, {
          [styles.loginLogo]: isLogin,
        })}
      />
      <span
        className={cn(styles.logoText, {
          [styles.loginLogoText]: isLogin,
        })}
      >
        STRIBOG
      </span>
    </div>
  );
};
