import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import styles from './ContentLayout.module.css';

interface ContentLayoutProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ContentLayout = ({
  children,
  className,
  ...props
}: ContentLayoutProps) => {
  return (
    <div className={cn(styles.ContainerLayout, className)} {...props}>
      {children}
    </div>
  );
};

export default ContentLayout;
