export const secondsToHms = (seconds: number): string => {
  seconds = Math.floor(seconds); // Ensure seconds are rounded down to the nearest integer
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');
  return `${h}:${m}:${s}`;
};

export const hmsToSeconds = (hms: string): number => {
  const [hours, minutes, seconds] = hms.split(':').map(parseFloat);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return isNaN(totalSeconds) ? 0 : totalSeconds;
};
