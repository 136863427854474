import React from 'react';
import './Input.css';

interface InputProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
}

const Input: React.FC<InputProps> = ({ value, onChange, title }) => {
  return (
    <div>
      <div className="Label">
        <label htmlFor="title">{title}</label>
      </div>
      <input
        value={value}
        name={title}
        onChange={(e) => onChange(e.target.value)}
        className="Input"
      />
    </div>
  );
};

export default Input;
